import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { motion } from 'framer-motion'
import { useMount } from 'react-use'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Block } from '../components'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../styles/global'
import { media } from '../styles/utils'

const Default = ({ pageContext, ...props }) => {
	const data = pageContext
	
	// Blocks

	const renderBlocks = (blocks) => {
		if (!data?.acf?.blocks) return;
	
		return pageContext.acf.blocks.map((block, i) => {  
			return (
				<Block
					layout={block.acf_fc_layout}
					display={block.display}
					key={i}
					{...block}
				/>
			)
		})
	}

    return (
        <Layout meta={data?.acf?.seo}>
			<Container>
				<Title>{pageContext.title}</Title>
				<Text dangerouslySetInnerHTML={{ __html: pageContext.acf.introduction }} />

				{renderBlocks()}
            </Container>
        </Layout>
    )
}

// Shared

const Title = styled.div``
const SubHeading = styled.div``
const Text = styled.div``

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
	Margin-top: 200px;

	${Title} {
		${type.heading1};
		padding-bottom: 32px;
	}

	${Text} {
		${type.body};
		padding-bottom: 40px;
		max-width: 80%;

		${media.tablet`
			max-width: 100%;	
		`}
	}
`


export default Default
